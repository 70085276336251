import {
  TableCategory,
  TableHeaders,
  CellContentType
} from '@/shared/components/table/models';

export const NavTracHeaders: TableHeaders[] = [
  {
    key: 'date',
    label: `Date`,
    sortable: true
  },
  {
    key: 'date',
    label: `Date`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'orderNumber',
    label: `WO #`,
    sortable: true
  },
  {
    key: 'type',
    label: `Type`,
    sortable: true
  },
  {
    key: 'time',
    label: `Time`,
    sortable: true,
    disabled: true,
    // content: {
    //   type: CellContentType.Time
    // }
  },
  {
    key: 'div',
    label: `DIV`,
    sortable: true
  },
  {
    key: 'yardId',
    label: `Yard ID`,
    sortable: true
  },
  {
    key: 'yard',
    label: `Yard`,
    sortable: true
  },
  {
    key: 'loca',
    label: `LOCA`,
    sortable: true
  },
  {
    key: 'truckNumber',
    label: `Truck #`,
    sortable: true
  },
  {
    key: 'company',
    label: `Company`,
    sortable: true
  },
  {
    key: 'containerNumber',
    label: `CONT #`,
    sortable: true
  },
  {
    key: 'containerSize',
    label: `SZ`,
    sortable: true
  },
  {
    key: 'shippingLine',
    label: `SSL`,
    sortable: true
  },
  {
    key: 'sealNumber',
    label: `Seal #`,
    sortable: true
  },
  {
    key: 'chassisNumber',
    label: `Chassis #`,
    sortable: true
  },
  {
    key: 'chassisPool',
    label: `POOL`,
    sortable: true
  },
  {
    key: 'updatedBy',
    label: `Updated By`,
    sortable: true
  }
];
