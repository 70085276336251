





























import { Component, Vue } from 'vue-property-decorator';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { TableHeaders } from '@/shared/components/table/models';

import { AccountingModule } from '@/store/index';

import { NavTracHeaders } from './models/NavTracHeaders';

import InventoryAction from '@/pages/YMS/components/InventoryAction.vue';
import NavTracFilters from '@/pages/YMS/NavTrac/NavTracFilters.vue';

@Component({
  components: { TmsTable, NavTracFilters, InventoryAction }
})
export default class NavTrac extends Vue {
  AccountingModule = AccountingModule;

  columns: TableHeaders[] = NavTracHeaders;
  inventory: any = {};

  get rows() {
    const a = [];
    for (let index = 0; index < 100; index++) {
      a.push({
        company: `comany ${index}`,
        date: '20210428000000',
        orderNumber: `order ${index}`,
        billTo: `bill to ${index}`,
        containerNumber: `containerNumber ${index}`
      });
    }
    return a;
  }

  constructor() {
    super();
  }

  openModal(a, b) {
    console.log(a, b);
    this.$bvModal.show('inventory-action-modal');
  }
}
